// extracted by mini-css-extract-plugin
export var imgViewCard = "index-module--imgViewCard--OMDgn";
export var jdLink = "index-module--jdLink---LSo1";
export var machContainer = "index-module--machContainer--UcDR7";
export var machDesc = "index-module--machDesc--F2z0s";
export var machInfo = "index-module--machInfo--MfWBm";
export var machItem = "index-module--machItem--bMJXk";
export var machItemActive = "index-module--machItemActive--99Tjq";
export var machOperate = "index-module--machOperate--97j9e";
export var machSwiper = "index-module--machSwiper--0FDOl";
export var machTitle = "index-module--machTitle--GsH10";
export var navButton = "index-module--navButton--pPsQ8";
export var niceDesc = "index-module--niceDesc--wKSbH";
export var niceIcon = "index-module--niceIcon--TpRvH";
export var niceItem = "index-module--niceItem--4gTGS";
export var niceList = "index-module--niceList--aeHxQ";
export var niceTitle = "index-module--niceTitle--oCc4C";
export var normsTable = "index-module--normsTable--EttAj";
export var tdBase = "index-module--tdBase--LJY28";
export var type2 = "index-module--type2--5I9zt";